/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import { sansayList } from '../../config';
import { useNavigate, useParams, Link } from 'react-router-dom';
import CreateInputForm from '../comman/CreateInputForm';
import DisplayTable from '../comman/DisplayTable';
import CreateOutboundInputForm from '../comman/CreateOutboundInputForm';
import DisplayOutboundTable from '../comman/DisplayOutboundTable';
import ApiSecretGeneration from "../comman/ApiSecretGeneration";
import CompanyCiaDetail from '../comman/CompanyCiaDetail';
import parseDateWithoutTimezone from "../../UTCDateFormat";

 
const EditUser = () => {
    const { id } = useParams();
    const [name, setName] = useState('');
    // const [company, setCompany] = useState([]);
    const [role, setRole] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [msg, setMsg] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [access, setAccess] = useState([]);
    const [carrierTrunk, setCarrierTrunk] = useState([]);
    const [valueList, setValueList] = useState([]);
    const [applicationList, setApplicationList] = useState([]);
    const [outboundTrunkId, setOutboundTrunkId] = useState([]);
    const [outboundValueList, setOutboundValueList] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [trunkDisabled, setTrunkDisabled] = useState(false);
    const [outBoundTrunkDisabled, setoutBoundTrunkDisabled] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);  
    const [changeDetectSwitch, setChangeDetectSwitch] = useState(true);
    const [changeDetectInput, setChangeDetectInput] = useState(120);
    const [formData, setFormData] = useState({
        user_role: '0',
        cname: '',
        short_name: "",
        cid: '',
        fname: '',
        lname: '',
        email: '',
        officephone: '',
        mobilephone: '',
        pbx_switch: [],
        pbx_url:{},
        pbx_username:'',
        pbx_password:'',
        timezone: '',
        route_switch: false,
        e911_switch: false,
        cname_switch: false,
        digit_mapping_switch: false,
        advance_digit_mapping_switch: false,
        report_switch : false,
        inventory_switch : false,
        multiple_inventory_switch : false,
        api_enable_switch : false,
        api_access_count : '',
        api_secret : '',
        api_username : '',
        api_daily_switch : false,
        api_daily_access_count: ''
    });
    const [accessRight, setAccessRight] = useState({
        createuser: false,
        edituser: false,
        deleteuser: false,
        viewuser: false,
        ordernewnumber: false,
        viewtransactions: false,
        modifynumber: false,
        cancelnumber: false,
        viewinventory: false
    });
    const [setting, setSetting] = useState({
        disconnectSwitch: true,
        disconnectday: 120,
    });
    const [ciaAccessData, setCiaAccessData] = useState({
        cia_id: null,
        cas_type: null,
        switch_action : null,
        cia_enable_switch: false,
        cia_new_number_switch: false,
        cia_new_order_switch: false,
        cia_account_type: '',
        cia_report_type: 1,
        occurance_type: 1,
        display_date: "",
        format_date: "",
        one_off_time: "",
        schedule_type: 1,
        recurring_type: 1,
        day_of_week: "",
        day_of_month: "",
        every_x_day: "",
        recurring_time: "",
        start_date: "",
        start_date_display: "",
        start_time: ""
    })
    const [trunkData, setTrunksData] = useState([]);
    const [bandwdithDidData, setBandwdithDidData] = useState([]);
    const [bandwdithTollFreeData, setBandwdithTollFreeData] = useState([]);
    const [selectDidAccessData, setSelectDidAccessData] = useState([]);
    const [selectTollFreeAccessData, setSelectTollFreeAccessData] = useState([]);
    const [outboundTrunkData, setOutboundTrunkData] = useState([]);
    const [whiteLabelData, setWhiteLabelData] = useState([]);
    const [trunkId, setTrunkId] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);

    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
      }, []);
    
      useEffect(() => {
        if (token) {
        //   getUserCompany();
          getUserDetail();
          getUserAccess();
          getCarrierTrunk();
          getApplicationList();
        }
      }, [token]);
      useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
        
    }, [access, token]);

   
    const refreshToken = async () => {
        try {
            setPopupOpen(true);
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axios.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const getApplicationList = async () => {
        try {
            const response = await axios.get(`${baseUrl}/get-application-list`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            console.log(response.data.data);
            setApplicationList(response.data.data);
            // setIsLoading(false);

        } catch (error) {
            console.log(error);
        }
    }
    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
      };

    const handleSwitchChange = (event) => {
        const { name, checked } = event.target;
        const prevValue = setting[name]; 
        setSetting((prevSetting) => ({
            ...prevSetting,
            [name]: checked,
        }));
        setChangeDetectSwitch(prevValue);
    };

    const handlePBXSwitchChange = (event) => {

        const { name, checked } = event.target;
        const id = parseInt(name, 10); // Convert the string to a number

        setFormData((prevData) => {
            // If checked, add the numeric ID to the array; if unchecked, remove it
            const updatedPBXSwitch = checked
                ? [...prevData.pbx_switch, id] // Add the number to the array
                : prevData.pbx_switch.filter((item) => item !== id); // Remove the number from the array
            console.log({
                ...prevData,
                pbx_switch: updatedPBXSwitch,
            });
            return {
                ...prevData,
                pbx_switch: updatedPBXSwitch,
            };
        });
    }

    const handleApplicationInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            pbx_url: {
                ...prevFormData.pbx_url,
                [name]: value || '' // Set the value or empty string if value is falsy
            }
        }));
    }

    const handleRouteSwitchChange = (event) => {
        const { name, checked } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    }
    const handleE911SwitchChange = (event) => {
        const { name, checked } = event.target;
        setFormData((prevSetting) => ({
            ...prevSetting,
            [name]: checked,
        }));
    };
    const handleDayChange = (event) => {
        const { name, value } = event.target;
        const prevValue = setting[name]; 
        setSetting((prevSetting) => ({
            ...prevSetting,
            [name]: value ? parseInt(value) : 0,
        }));
        setChangeDetectInput(prevValue ? parseInt(prevValue) : 0);
    };

    const addToValueList = (value) => { 
        console.log(value);     
        // setValueList((prevValueList) => [...prevValueList, value]);
        checkTrunks(value);
        setTrunkId(value);
    };

    const addToOutboundValueList = (value) => { 
        console.log(value);     
        
        checkOutboundTrunks(value);
        setOutboundTrunkId(value);
    };

    const removeFromValueList = (index) => {
        const newList = valueList.filter((_, i) => i !== index);
        setValueList(newList);
        
        const data = trunkData.filter((_, i) => i !== index);
        setTrunksData(data);

        const bandwdithdata = bandwdithDidData.filter((_, i) => i !== index);
      
        setBandwdithDidData(bandwdithdata);

        const bandwdithTollFree = bandwdithTollFreeData.filter((_, i) => i !== index);
      
        setBandwdithTollFreeData(bandwdithTollFree);

        const didAccessList = selectDidAccessData.filter((_, i) => i !== index);
        setSelectDidAccessData(didAccessList);

        const selectTollFreeAccess = selectTollFreeAccessData.filter((_, i) => i !== index);
        setSelectTollFreeAccessData(selectTollFreeAccess);
    };

    const removeFromOutboundValueList = (index) => {
        const newList = outboundValueList.filter((_, i) => i !== index);
        setOutboundValueList(newList);
        
        const data = outboundTrunkData.filter((_, i) => i !== index);
        
        setOutboundTrunkData(data);
        
    };

    const checkTrunks = async (trunkList) => {
        try {
            setTrunkDisabled(true);
            setPopupOpen(true);
            const response = await axios.post(`${baseUrl}/get-sansy-data`, { trunkList: trunkList, type:"inbound" });
            console.log(response.data.data);
            setTrunkDisabled(false);
            const newData = response.data.data.filter((newValue) => {
                return !valueList.some((existingValue) => existingValue.trunkId == newValue.trunkId);
            });

            // console.log(newData);
              setValueList((prevValueList) => [...prevValueList, ...newData]);
            // setValueList((prevValueList) => [...prevValueList, ...response.data.data]);
        
            setPopupOpen(false);
        } catch (error) {
            console.log(error);
            setTrunkDisabled(false);
            setPopupOpen(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const checkOutboundTrunks = async (trunkList) => {
        try {
            setoutBoundTrunkDisabled(true);
            setPopupOpen(true);
            const response = await axios.post(`${baseUrl}/get-sansy-data`, { trunkList: trunkList, type:"outbound" });
            console.log(response.data);
            setoutBoundTrunkDisabled(false);
            const newData = response.data.data.filter((newValue) => {
                return !outboundValueList.some((existingValue) => existingValue.trunkId === newValue.trunkId);
              });

              setOutboundValueList((prevValueList) => [...prevValueList, ...newData]);
            
            setPopupOpen(false);
           
        } catch (error) {
            console.log(error);
            setoutBoundTrunkDisabled(false);
            setPopupOpen(false);
          
        }
    }

    const handleCheckboxChange = (event, name, type, index) => {
        const isChecked = event.target.checked;
        
        if(type === 0){
            
            setTrunksData((prevTrunkData) => {
                const newTrunkData = [...prevTrunkData];
                
                  newTrunkData[index] = name;
               
                return newTrunkData;
              });
        }else{
            if (isChecked) {
                setWhiteLabelData([...whiteLabelData, name]);
            } else {
                setWhiteLabelData(whiteLabelData.filter(n => n !== name));
            }
        }
        
    };

    const handleBandwidthDidCheckboxChange = (event, name, type, index) => { 
        const isChecked = event.target.checked;
        if(type === 0){
            
                
                setBandwdithDidData((prevTrunkData) => {
                    const newTrunkData = [...prevTrunkData];
                   
                      newTrunkData[index] = name;
                   
                    return newTrunkData;
                  });
        }else{
            if (isChecked) {
                setWhiteLabelData([...whiteLabelData, name]);
            } else {
                setWhiteLabelData(whiteLabelData.filter(n => n !== name));
            }
        }
        
    };

    const handleBandwidthTollFreeCheckboxChange = (event, name, type, index) => { 
        const isChecked = event.target.checked;
        if(type === 0){
            
                
                setBandwdithTollFreeData((prevTrunkData) => {
                    const newTrunkData = [...prevTrunkData];
                   
                      newTrunkData[index] = name;
                   
                    return newTrunkData;
                  });
        }else{
            if (isChecked) {
                setWhiteLabelData([...whiteLabelData, name]);
            } else {
                setWhiteLabelData(whiteLabelData.filter(n => n !== name));
            }
        }
        
    };

    const handleDidAccessCheckboxChange = (event, name, type, index) => { 
        const isChecked = event.target.checked;
        if(type === 0){
            
                
            setSelectDidAccessData((prevTrunkData) => {
                    const newTrunkData = [...prevTrunkData];
                   
                      newTrunkData[index] = name;
                   
                    return newTrunkData;
                  });
        }else{
            if (isChecked) {
                setWhiteLabelData([...whiteLabelData, name]);
            } else {
                setWhiteLabelData(whiteLabelData.filter(n => n !== name));
            }
        }
        
    };

    const handleTollFreeAccessCheckboxChange = (event, name, type, index) => { 
        const isChecked = event.target.checked;
        if(type === 0){
            
                
            setSelectTollFreeAccessData((prevTrunkData) => {
                    const newTrunkData = [...prevTrunkData];
                   
                      newTrunkData[index] = name;
                   
                    return newTrunkData;
                  });
        }else{
            if (isChecked) {
                setWhiteLabelData([...whiteLabelData, name]);
            } else {
                setWhiteLabelData(whiteLabelData.filter(n => n !== name));
            }
        }
        
    };

    const handleOutboundCheckboxChange = (event, name, type, index) => {
        console.log(index);
        const isChecked = event.target.checked;
       
        
        if(type === 0){
            
                
                setOutboundTrunkData((prevTrunkData) => {
                    const newTrunkData = [...prevTrunkData];
                   
                      newTrunkData[index] = name;
                   
                    return newTrunkData;
                  });
        }else{
            // if (isChecked) {
            //     setWhiteLabelData([...whiteLabelData, name]);
            // } else {
            //     setWhiteLabelData(whiteLabelData.filter(n => n !== name));
            // }
        }
        
        
    };
    useEffect(() => {
            console.log(outboundTrunkData);
        },[outboundTrunkData])


    const checkAccess = async (access) => {
        if((role !== 2 && role !== 1 && role !== 0)|| (role === 2 && !hasPermission('edituser'))){
            navigate('/dashboard');
        }
      
    }
   
   

    const getUserDetail = async () => {
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/user-company/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        // console.log(userData.pbx_password);
        const userData = response.data.user[0];
            console.log(userData);
        setFormData({
            // user_role: userData.role, // Convert to string if needed
            cname: userData.cname,
            short_name: userData.short_name,
            cid: userData.cid,
            fname: userData.fname,
            lname: userData.lname,
            email: userData.email,
            officephone: userData.office_phone,
            mobilephone: userData.mobile_phone,
            pbx_switch: JSON.parse(userData.pbx_switch) ?? [],
            pbx_url: JSON.parse(userData.pbx_url) ?? {},
            pbx_username: userData.pbx_username,
            pbx_password: userData.pbx_password,
            timezone: userData.timezone,
            route_switch: userData.route_switch === 1,
            e911_switch : userData.e911_switch === 1,
            digit_mapping_switch: userData.digit_mapping_switch === 1,
            advance_digit_mapping_switch : userData.advance_digit_mapping_switch === 1,
            cname_switch: userData.cname_switch === 1,
            report_switch : userData.report_switch === 1,
            inventory_switch: userData.inventory_switch === 1,
            multiple_inventory_switch : userData.multiple_inventory_switch === 1,
            api_enable_switch: userData.api_enable_switch === 1,
            api_access_count: userData.api_access_count,
            api_secret : userData.api_secret,
            api_username : userData.api_username,
            api_daily_switch: userData.api_daily_switch === 1,
            api_daily_access_count : userData.api_daily_access_count ?? 0,
            capi_daily_usage_switch: userData.capi_daily_usage_switch === 1,
            capi_daily_usage_count: userData.capi_daily_usage_count ?? 0,
            capi_monthly_number_order_count: userData.capi_monthly_number_order_count ?? 0,
            enable_bap_api: userData.enable_bap_api === 1,
            enable_tns_api: userData.enable_tns_api === 1,
          });
          const userAccessData = response.data.user_access;

          const updatedAccessRight = { ...accessRight }; // Create a copy of the state
      
          userAccessData.forEach((access) => {
            const { access: accessName, value } = access;
            updatedAccessRight[accessName] = value === 1; // Convert value to boolean
          });
          setCiaAccessData({
            cia_id: userData?.cia_id,
            cas_type: userData.cas_type === 1 ? true : false,
            switch_action : null,
            cia_enable_switch: userData.cia_enable_switch === 1 ? true : false,
            cia_new_number_switch: userData.cia_new_number_switch === 1 ? true : false,
            cia_new_order_switch: userData.cia_new_order_switch === 1 ? true : false,
            cia_account_type: userData.cia_account_type,
            cia_report_type: userData.cia_report_type ?? 1,
            occurance_type: userData?.occurance_type ?? 1,
            display_date: userData?.format_date ? parseDateWithoutTimezone(userData?.format_date) : null,
            format_date: userData?.format_date ?? null,
            one_off_time: userData?.one_off_time ?? null,
            recurring_type: userData?.recurring_type ?? 1,
            day_of_week: userData?.day_of_week ?? null,
            day_of_month: userData?.day_of_month ?? null,
            every_x_day: userData?.every_x_day ?? null,
            recurring_time: userData?.recurring_time ?? null,
            start_date: userData?.start_date ?? null,
            start_date_display: userData?.start_date ? parseDateWithoutTimezone(userData?.start_date) : null,
            start_time: userData?.start_time ?? null
          })
          setAccessRight(updatedAccessRight);

          const userSettingData = response.data.user_setting[0];
          
          if (userSettingData) {
            setSetting({
                disconnectSwitch: userSettingData.disconnect_switch === 1,
                disconnectday: userSettingData.disconnect_day,
            });
            setChangeDetectSwitch(userSettingData.disconnect_switch === 1);
            setChangeDetectInput(userSettingData.disconnect_day);
        } 
        // console.log(setting.disconnectSwitch);

          const userTrunk = response.data.user_trunk;
          setTrunksData(userTrunk);
          const userLabel = response.data.user_white_label;
          const userBandwidthDid = response.data.user_bandwidth_did;
          const userBandwidthTollFree = response.data.user_bandwidth_toll_free;
          setBandwdithDidData(userBandwidthDid);
          setBandwdithTollFreeData(userBandwidthTollFree);
          const userDidAccess = response.data.user_did_access;
          const userTollFreeAccess = response.data.user_toll_free_access;
        setSelectDidAccessData(userDidAccess);
        setSelectTollFreeAccessData(userTollFreeAccess);
          

          const outboundData = response.data.user_outbound;
          const outboundTrunkData = response.data.user_outbound_trunk;
        //   console.log(outboundData);
          setWhiteLabelData(userLabel);
          setValueList(userLabel);
          setOutboundTrunkData(outboundTrunkData);
          setOutboundValueList(outboundData);
          setCheckedItems(JSON.parse(userData.sansay_priority) ?? []);
        // console.log(userData);
    };
    const [validationErrors, setValidationErrors] = useState({
        user_role: false,
        email: false,
        cname: false,
        short_name: false,
        fname: false,
        lname: false,
        officephone: false,
        mobilephone: false,
        timezone: false
        // tid:false

    });
    const formRef = useRef(null);
    const validateEmail = (email) => {
        // Implement your email validation logic here
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const newValidationErrors = {
            user_role: formData.user_role === '0',
            email: !validateEmail(formData.email),
            cname: formData.cname === '',
            short_name : formData.short_name == '' || formData.short_name == null || formData.short_name.length > 26,
            fname: formData.fname === '',
            lname: formData.lname === '',
            officephone: !/^\d{1,10}$/.test(formData.officephone),
            mobilephone: !/^\d{1,10}$/.test(formData.mobilephone),
            timezone: formData.lname === ''
            // tid: valueList.some(item => item.isValid === false)


          };
          setValidationErrors(newValidationErrors);
      
          // Check if there are any validation errors
          const hasErrors = Object.values(newValidationErrors).some((error) => error);
      
          if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            console.log(firstInvalidInput);
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          } else {
          
           let checkOption = true;          
           if(setting.disconnectSwitch != changeDetectSwitch || setting.disconnectday != changeDetectInput){            
                const userConfirmed = window.confirm("Are you sure you want to change the minimum disconnect option?");
                if (userConfirmed) {
                    checkOption = true;
                }else{
                    checkOption = false;
                }
            }
            if (ciaAccessData.recurring_type == 2 && (ciaAccessData.day_of_week == null || ciaAccessData.day_of_week == '' || ciaAccessData.day_of_week == 0)) {
                alert("Please select a day of week in CAS enabled number");
                return
            };
            if (ciaAccessData.recurring_type == 3 && (ciaAccessData.day_of_month == null || ciaAccessData.day_of_month == '' || ciaAccessData.day_of_month == 0)) {
                alert("Please select a day of month in CAS enabled number");
                return
            };
            if (ciaAccessData.recurring_type == 4 && (ciaAccessData.every_x_day == null || ciaAccessData.every_x_day == '' || ciaAccessData.every_x_day == 0)) {
                alert("Please enter valid value in CAS enabled number");
                return
            };
           if(checkOption){
           
                const rightsArray = Object.entries(accessRight).map(([key, value]) => ({
                    name: key,
                    value: value ? 1 : 0,
                  }));
              
                  const combinedData = {
                    formData: formData,
                    ciaAccessData: ciaAccessData,
                    rightsArray: rightsArray,
                    setting :setting,
                    trunks: trunkData,
                    whitelabel:whiteLabelData,
                    newTid: valueList.filter(value => value.isValid),
                    outboundTid: outboundValueList.filter(value => value.isValid),
                    outboundTrunkData : outboundTrunkData,
                    bandwdithDidData:bandwdithDidData,
                    bandwdithTollFreeData:bandwdithTollFreeData,
                    selectDidAccessData : selectDidAccessData,
                    selectTollFreeAccessData:selectTollFreeAccessData,
                    sansayPriority:checkedItems
                  };
                  console.log(combinedData);
                try {
                    setButtonDisabled(true);
                    setPopupOpen(true);
                    await axios.post(`${baseUrl}/user-company/${id}`,combinedData,{headers: {
                        Authorization: `Bearer ${token}`
                    }});
                    setIsSuccess(true);
                    navigate("/user-company/list");
                } catch (error) {
                    setButtonDisabled(false);
                    setPopupOpen(false);
                    if (error.response) {
                        setIsSuccess(false);
                        setMsg(error.response.data.msg);
                    }
                }
            }
          }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'user_role' && value <= 2) {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                cname: '1', // Set cname to '1' if user_role is less than or equal to 2
                short_name: '1'
            }));
            setTrunksData([]);
            setWhiteLabelData([]);
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };

      const accessRightLabels = {
        createuser: 'Create Users',
        edituser: 'Edit Users',
        deleteuser: 'Delete Users',
        viewuser: 'View Users',
        ordernewnumber: 'Order New Number',
        viewtransactions: 'View Transations',
        modifynumber: 'Modify Number',
        cancelnumber: 'Cancel Number',
        viewinventory: 'View Inventory'        
      };
    

    // const handleTrunkInputChange = (e, name) => {
    //     const newValue = e.target.value;
    //     console.log(newValue);
    //     console.log(name);
    //     // Update trunkData if 'BBD_DID_LA1_1900' is in trunkData
    //     if (trunkData.some(item => item.name === name)) {
    //         setTrunksData(trunkData.map(item => 
    //         item.name === name ? { ...item, value: newValue } : item
    //       ));
    //     }
        
    // };
    const handleTrunkInputChange = (e, name, index) => {
        const newValue = e.target.value;
        
      
        // Update trunkData at the specified index
        if (index !== undefined && trunkData[index]?.name === name) {
          const updatedTrunkData = [...trunkData];
          updatedTrunkData[index] = { ...updatedTrunkData[index], value: newValue };
          setTrunksData(updatedTrunkData);
        }
    };

    const handleOutboundTrunkInputChange = (e, name, index) => {
        const newValue = e.target.value;
        // console.log(newValue);
        console.log(e.target.value);
      
        
        if (index !== undefined && outboundTrunkData[index]?.name === name) {
          const updatedTrunkData = [...outboundTrunkData];
          updatedTrunkData[index] = { ...updatedTrunkData[index], value: newValue };
          setOutboundTrunkData(updatedTrunkData);
        }
    };

    const getCarrierTrunk = async () => {
        try {
            setPopupOpen(true);
            const response = await axios.get(`${baseUrl}/carrier-trunk`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            setPopupOpen(false);
            // console.log(response.data);
            setCarrierTrunk(response.data.data);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
           setPopupOpen(false);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const timeZones = [
        { value: "EST", label: "Eastern Standard Time" },
        { value: "CST", label: "Central Standard Time" },
        { value: "MST", label: "Mountain Standard Time" },
        { value: "PST", label: "Pacific Standard Time" },
        { value: "AKST", label: "Alaska Standard Time" },
        { value: "HAST", label: "Hawaii-Aleutian Standard Time" },
        { value: "AST", label: "Atlantic Standard Time" },
        { value: "ChST", label: "Chamorro Standard Time" }
    ];
      
    // Sort the time zones alphabetically by label
    timeZones.sort((a, b) => a.label.localeCompare(b.label));

    

    const handleSansayPriorityCheckbox = (itemName, isChecked) => {
        if (isChecked) {
            setCheckedItems(prevCheckedItems => [...prevCheckedItems, itemName]);
        } else {
            setCheckedItems(prevCheckedItems => prevCheckedItems.filter(item => item !== itemName));
        }
    };

    const updateCiaAccessData = (update) => {
        setCiaAccessData(update)
    }
    const updatedTNSDetail = (updatedData) => {
        setFormData((prevData) => ({
            ...prevData,
            api_access_count: updatedData.api_access_count,
            api_daily_access_count: updatedData.api_daily_access_count,
            api_daily_switch: updatedData.api_daily_switch,
            api_enable_switch:updatedData.api_enable_switch,
            capi_daily_usage_switch:updatedData.capi_daily_usage_switch,
            capi_daily_usage_count:updatedData.capi_daily_usage_count,
            capi_monthly_number_order_count:updatedData.capi_monthly_number_order_count,
            enable_bap_api:updatedData.enable_bap_api,
            enable_tns_api:updatedData.enable_tns_api,
        }));
    }

    
    return (
        <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
                <Header name={name} />
                {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page admin-new-addstaff-page">
                    <div className="fullrow">
                        <div className="row">
                            <div className="col-6">
                                <div className="left-title">
                                    <h1>Edit Customer</h1>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="addnew-button">
                                    <Link to="/user-company/list"><span>&larr;</span> Back</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                            <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>{msg}</p>
                                <div className="edit-staff-data-form add-new-staff-form">
                                    <form className="form-box" onSubmit={handleSubmit} ref={formRef}>
                                        <div className="common-white-shadow-background">
                                            <div className="innerboxwidth">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h3>User Info</h3>
                                                    </div>
                                                    {/* <div className="col-6">
                                                        <select name="user_role" id="user_role"  value={formData.user_role} onChange={handleInputChange} className={validationErrors.user_role ? 'invalid' : ''}>
                                                            <option value="0">User Types</option>                
                                                            {role === 0 && <option value="1">Super Admin</option>}
                                                            {role <= 1 && <option value="2">Support User</option>}
                                                            {role <= 2 && <option value="3">Reseller admin user</option>}
                                                            {role <= 3 && <option value="4">Customer admin user</option>}
                                                            {role <= 4 && <option value="5">Customer user</option>}
                                                        </select>
                                                        {validationErrors.user_role && <div className="error-message">Please select a user role.</div>}
                                                    </div> */}
                                                    <div className="col-md-12">
                                                        {/* <select name="cname" value={formData.cname} onChange={handleInputChange} className={validationErrors.cname ? 'invalid' : ''} >
                                                            <option value="">Select a company</option>
                                                            {company.map((comp, index) => (
                                                              <option key={index} value={comp.id}>
                                                                {comp.cname}
                                                              </option>
                                                            ))}
                                                        </select> */}
                                                        <label>Company Name</label>
                                                        <input type="text" name="cname" defaultValue={formData.cname}
                                                        placeholder="Company Name*" disabled={formData.user_role >=2} onChange={handleInputChange} className={validationErrors.cname ? 'invalid' : ''} />
                                                        {validationErrors.cname && <div className="error error-message">Please Enter Company Name.</div>}
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label>Short Company Name</label>
                                                        <input type="text" name="short_name" defaultValue={formData.short_name}
                                                        placeholder="Short Company Name*" disabled={formData.user_role >=2} onChange={handleInputChange} className={validationErrors.short_name ? 'invalid' : ''} />
                                                        {validationErrors.short_name && <div className="error error-message">Please Enter up to 25 characters for short company name.</div>}
                                                    </div>
                                                    <div className="col-md-6">
                                                    <label>First Name</label>
                                                        <input type="text" name="fname" defaultValue={formData.fname} placeholder="First Name*" onChange={handleInputChange} className={validationErrors.fname ? 'invalid' : ''} />
                                                        {validationErrors.fname && <div className="error error-message">Please Enter First Name.</div>}
                                                    </div>
                                                    <div className="col-md-6">
                                                    <label>Last Name</label>
                                                        <input type="text" name="lname" defaultValue={formData.lname} placeholder="Last Name*"  onChange={handleInputChange} className={validationErrors.lname ? 'invalid' : ''} />
                                                        {validationErrors.lname && <div className="error error-message">Please Enter last name.</div>}
                                                    </div>
                                                    <div className="col-sm-12">
                                                    <label>Email</label>
                                                        <input type="email" name="email" disabled={formData.user_role >=2} defaultValue={formData.email} placeholder="Email"  onChange={handleInputChange} className={validationErrors.email ? 'invalid' : ''}/>
                                                        {validationErrors.email && <div className="error error-message">Please enter a valid email address.</div>}
                                                    </div>

                                                    <div className="col-sm-6">
                                                    <label>Office Phone Number</label>
                                                        <input type="number" name="officephone" defaultValue={formData.officephone}
                                                            pattern="[0-9]*"  placeholder="Office Phone Number*" onChange={handleInputChange} className={validationErrors.officephone ? 'invalid' : ''} />
                                                            {validationErrors.officephone && <div className="error error-message">Please enter a valid officephone.</div>}
                                                    </div>
                                                    <div className="col-sm-6">
                                                    <label>Mobile Phone Number</label>
                                                        <input type="number" name="mobilephone" defaultValue={formData.mobilephone}
                                                            pattern="[0-9]*"  placeholder="Mobile Phone Number*" onChange={handleInputChange} className={validationErrors.mobilephone ? 'invalid' : ''} />
                                                            {validationErrors.mobilephone && <div className="error error-message">Please enter a valid mobilephone.</div>}
                                                    </div>
                                                    {/* {formData.user_role >= '2' && <div className="col-sm-12">
                                                        <p>Access Rights</p>
                                                        <div className="checkboximage">
                                                        {Object.entries(accessRight).map(([key, value]) => (
                                                            <label key={key} htmlFor={key}>
                                                            <input type="checkbox" name={key} id={key} checked={value} onChange={(e) =>
                                                                setAccessRight((prevRights) => ({
                                                                  ...prevRights,
                                                                  [key]: e.target.checked,
                                                                }))}
                                                            />
                                                            {accessRightLabels[key]}
                                                            </label>
                                                        ))}
                                                        </div>
                                                    </div>} */}
                                                </div>
                                            </div>
                                        </div>
                                    
                                    {(role == '0' || role == '1' || role == '3' || (role == '2' && hasPermission('createuser'))) && <div className="common-white-shadow-background setting-section">
                                        <div className="innerboxwidth">
                                            <div className="row">
                                                <h3>Settings</h3>
                                                <div className="settingbox">
                                                    <label>Minimum Disconnect Option</label>
                                                    <div className="switchbtn">
                                                        <input type="checkbox" id="switch" className="checkbox" disabled={(role > 3)} checked={setting.disconnectSwitch === true} name="disconnectSwitch"  onChange={handleSwitchChange} /> <label
                                                            htmlFor="switch" className="toggle">
                                                            <p>
                                                                {/* OFF ON */}
                                                            </p>
                                                        </label>
                                                    </div>
                                                    <div className="daybox">
                                                        {setting.disconnectSwitch && (

                                                       
                                                        <label htmlFor="">Days
                                                         <input type="text" name="disconnectday"
                                                            placeholder="120" value={setting.disconnectday} onChange={handleDayChange} />
                                                            </label>
                                                            )}
                                                    </div>
                                                </div>
                                                {applicationList.length > 0 && applicationList.map((item) => (
                                                        <>
                                                            <div className="settingbox">
                                                                <label>{item.name}</label>
                                                                <div className="switchbtn">
                                                                    <input type="checkbox" id={item.id} className="checkbox" disabled={(role > 3)} checked={formData.pbx_switch.includes(item.id)} onChange={handlePBXSwitchChange} name={item.id} /> <label
                                                                        htmlFor={item.id} className="toggle">
                                                                        <p>

                                                                        </p>
                                                                    </label>
                                                                </div>

                                                            </div>
                                                            {formData.pbx_switch.filter((o) => { return o == item.id }).length > 0 && (<div className="row">
                                                                <div className="col-sm-12">
                                                                    <input type="text" name={item.id} value={formData.pbx_url[item.id]}
                                                                        placeholder={`${item.name} url`} onChange={handleApplicationInputChange} />

                                                                </div>
                                                                {/* <div className="col-sm-6">
                                                            <input type="text" name="pbx_username" defaultValue={formData.pbx_username}
                                                              placeholder="PBX cloud username" onChange={handleInputChange}  />
                                                            
                                                        </div>
                                                        <div className="col-sm-6">
                                                        <input type="password" name="pbx_password" defaultValue={formData.pbx_password}
                                                             placeholder="PBX cloud password" onChange={handleInputChange}  />
                                                            
                                                        </div> */}
                                                            </div>)}
                                                        </>))}
                                                    <div className="settingbox">
                                                        <label>E911</label>
                                                        <div className="switchbtn">
                                                            <input type="checkbox" id="e911_switch" className="checkbox"  disabled={(role > 3)} checked={formData.e911_switch === true}  name="e911_switch"  onChange={handleE911SwitchChange} /> <label
                                                                htmlFor="e911_switch" className="toggle">
                                                                <p>
                                                                    
                                                                </p>
                                                            </label>
                                                        </div>
                                                       
                                                    </div>
                                                    <div className="settingbox">
                                                        <label>CNAM</label>
                                                        <div className="switchbtn">
                                                            <input type="checkbox" id="cname_switch" className="checkbox"  disabled={(role > 3)} checked={formData.cname_switch === true}  name="cname_switch"  onChange={handleE911SwitchChange} /> <label
                                                                htmlFor="cname_switch" className="toggle">
                                                                <p>
                                                                    
                                                                </p>
                                                            </label>
                                                        </div>
                                                       
                                                    </div>
                                                    <div className="settingbox">
                                                        <label>Digit Mapping</label>
                                                        <div className="switchbtn">
                                                            <input type="checkbox" id="digit_mapping_switch" className="checkbox"  disabled={(role > 3)} checked={formData.digit_mapping_switch === true}  name="digit_mapping_switch"  onChange={handleE911SwitchChange} /> <label
                                                                htmlFor="digit_mapping_switch" className="toggle">
                                                                <p>
                                                                    
                                                                </p>
                                                            </label>
                                                        </div>
                                                       
                                                    </div>
                                                    {formData.digit_mapping_switch == true && <div className="settingbox">
                                                        <label>Advance Digit Mapping Option</label>
                                                        <div className="switchbtn">
                                                            <input type="checkbox" id="advance_digit_mapping_switch" className="checkbox" disabled={(role > 3)} checked={formData.advance_digit_mapping_switch === true} name="advance_digit_mapping_switch" onChange={handleE911SwitchChange} /> <label
                                                                htmlFor="advance_digit_mapping_switch" className="toggle">
                                                                <p>

                                                                </p>
                                                            </label>
                                                        </div>

                                                    </div>}
                                                    <div className="settingbox">
                                                        <label>Traffic and Analytics Reporting</label>
                                                        <div className="switchbtn">
                                                            <input type="checkbox" id="report_switch" className="checkbox"  disabled={(role > 3)} checked={formData.report_switch === true}  name="report_switch"  onChange={handleE911SwitchChange} /> <label
                                                                htmlFor="report_switch" className="toggle">
                                                                <p>
                                                                    
                                                                </p>
                                                            </label>
                                                        </div>
                                                       
                                                    </div>
                                                    <div className="settingbox">
                                                        <label>Inventory Listing</label>
                                                        <div className="switchbtn">
                                                            <input type="checkbox" id="inventory_switch" className="checkbox"  disabled={(role > 3)} checked={formData.inventory_switch === true}  name="inventory_switch"  onChange={handleE911SwitchChange} /> <label
                                                                htmlFor="inventory_switch" className="toggle">
                                                                <p>
                                                                    
                                                                </p>
                                                            </label>
                                                        </div>
                                                       
                                                    </div>
                                                    <div className="settingbox">
                                                        <label>Mutiple Inventory Edit</label>
                                                        <div className="switchbtn">
                                                            <input type="checkbox" id="multiple_inventory_switch" className="checkbox"  disabled={(role > 3)} checked={formData.multiple_inventory_switch === true}  name="multiple_inventory_switch"  onChange={handleE911SwitchChange} /> <label
                                                                htmlFor="multiple_inventory_switch" className="toggle">
                                                                <p>
                                                                    
                                                                </p>
                                                            </label>
                                                        </div>
                                                       
                                                    </div>
                                                    <div className="settingbox timezone">
                                                        <label>Select timeZone</label>
                                                        <div >
                                                        {<select name="timezone" id="timezone" value={formData.timezone} onChange={handleInputChange}>
                                                            <option value="">Timezone</option>                
                                                            {timeZones.map((zone) => (
                                                                  <option key={zone.value} value={zone.value}>
                                                                    {zone.value}
                                                                  </option>
                                                                ))}
                                                        </select> }
                                                        {validationErrors.timezone && <div className="error error-message">Please Select TimeZone.</div>}
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {(role == '0' || role == '1' ||role == '3' || (role == '2' && hasPermission('createuser'))) && 
                                            <ApiSecretGeneration baseData={formData} updatedTNSDetail={updatedTNSDetail} />
                                        }
                                     {(role == '0' || role == '1' || role == '3' || (role == '2' && hasPermission('createuser'))) &&
                                            <CompanyCiaDetail baseData={ciaAccessData} token={token} />
                                        }
                                    {(role == '0' || role == '1' || role == '3' ||(role == '2' && hasPermission('createuser'))) && <div className="common-white-shadow-background setting-section">
                                    <div className="row">
                                    <div className="col-5 white-label">
                                                    <h3>Customer Trunk Assignment</h3> 
                                            </div> 
                                            <div className="col-7 settingbox">
                                                        <label>Enhance Routing</label>
                                                        <div className="switchbtn">
                                                            <input type="checkbox" id="route_switch" className="checkbox"   name="route_switch"  checked={formData.route_switch === true} onChange={handleRouteSwitchChange} /> <label
                                                                htmlFor="route_switch" className="toggle">
                                                                <p>
                                                                    
                                                                </p>
                                                            </label>
                                                        </div>
                                                        
                                                    </div> 
                                    <CreateInputForm addToList={addToValueList} trunkDisabled={trunkDisabled} />
                                    <DisplayTable carrierTrunk={carrierTrunk} values={valueList} currentTrunk={trunkId} removeFromList={removeFromValueList} handleTrunkInputChange={handleTrunkInputChange} handleCheckboxChange={handleCheckboxChange} handleBandwidthDidCheckboxChange={handleBandwidthDidCheckboxChange} handleBandwidthTollFreeCheckboxChange={handleBandwidthTollFreeCheckboxChange} handleDidAccessCheckboxChange={handleDidAccessCheckboxChange} handleTollFreeAccessCheckboxChange={handleTollFreeAccessCheckboxChange} trunkData={trunkData} bandwdithDidData={bandwdithDidData}  bandwdithTollFreeData={bandwdithTollFreeData} selectDidAccessData={selectDidAccessData} selectTollFreeAccessData={selectTollFreeAccessData} />
                                    {/* {validationErrors.tid && <div className="error error-message">Please Enter valid TID.</div>} */}
                                    </div>
                                    </div>}

                                    {(role == '0' || role == '1' || role == '3' ||(role == '2' && hasPermission('createuser'))) && <div className="common-white-shadow-background setting-section">
                                            <div className="row">
                                               
                                            <div className="col-5 white-label">
                                                    <h3>Customer Outbound Trunk Assignment</h3> 
                                            </div> 
                                            <div className="col-7 settingbox">
                                                        
                                                        
                                            </div>  
                                            <CreateOutboundInputForm addToList={addToOutboundValueList} outBoundTrunkDisabled={outBoundTrunkDisabled}  />
                                            <DisplayOutboundTable  carrierTrunk={carrierTrunk} values={outboundValueList} currentTrunk={outboundTrunkId} removeFromList={removeFromOutboundValueList} handleOutboundTrunkInputChange={handleOutboundTrunkInputChange} handleOutboundCheckboxChange={handleOutboundCheckboxChange} outboundTrunkData={outboundTrunkData} />
                                            {/* {validationErrors.tid && <div className="error error-message">Please Enter valid TID.</div>} */}
                                            </div>
                                        </div> }
                                        <div className="common-white-shadow-background setting-section">
                                            <div className="row">
                                                <div className="col-5 white-label">
                                                    <h3>Sansays Priority (For reporting)</h3> 
                                                </div> 
                                                <div className="fullwidth-table track-table-body trunk-table staff-listing-admin-table-body">
                                                    <table>
                                                        {sansayList.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.name}</td>
                                                                <td><input type="checkbox"  checked= {checkedItems.length > 0 && checkedItems.includes(item.name) } onChange={(e) => handleSansayPriorityCheckbox(item.name, e.target.checked)} /></td>
                                                            </tr>
                                                        ))}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    <div className="col-12">
                                        <input type="submit" value="Update Customer" className={buttonDisabled ? 'yellow-button disabled-link' : 'yellow-button'} />
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                
            </div>
        </div>
    )
}
 
export default EditUser