import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import Navbar from '../Navbar'
import axios from 'axios';
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Header from '../Header';
import { saveAs } from 'file-saver';
import formatDateTimeToPST from "../PSTDateConvert";
import DatePicker from 'react-datepicker';
import Pagination from "react-js-pagination";
import Popup from './Popup';
import PopupImage from './PopupImage';
axios.defaults.timeout = 3000000; 


const CiaNumberDetail = () => {
    const location = useLocation();
    const { compid, type, carrier,filter } = location.state || {};
    const [name, setName] = useState('');
    const [cid, setCid] = useState('')
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [company, setCompany] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(compid);
    const [filterType, setFilterType] = useState(0);
    const [selectedCarrier,setSelectedCarrier] = useState(carrier ?? "0");
    const [selectedStatus,setSelectedStatus] = useState(type ?? "all");
    const [carrierColumnName, setCarrierColumnName] = useState([]);
    const [dateColumnName, setDateColumnName] = useState([]);
    const [carrierNumberList, setCarrierNumberList] = useState([]);
    const [casAccess, setCASAccess] = useState(false);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [showNoAccessMessage,setShowNoMessageAccess] = useState(false);
    const [filterSearch, setFilterSearch] = useState(false);
    const [tableData, setTableData] = useState([]);
    const today = new Date()
    const sixMonthsAgo = new Date(new Date().setMonth(today.getMonth() - 6));
    const [searchDate, setSearchDate] = useState(today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0'));
    const [searchEndDate, setSearchEndDate] = useState();
    const [displayDate, setDisplayDate] = useState(today);
    const [displayEndDate, setDisplayEndDate] = useState("");
    const [carrierList, setCarrierList] = useState([]);
    const [imageSrc, setImageSrc] = useState("");
    const [batchDates, setBatchDates] = useState([]);
    const [selectedBatchDate, setSelectedBatchDate] = useState("");
    const [batchList, setBatchList] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState("");

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupData, setPopupData] = useState(null);

    const [isPopupTwoOpen, setIsPopupTwoOpen] = useState(false);
    const [selectedDataTwo, setSelectedDataTwo] = useState(null);

    const [isAdvanceFieldChecked, setAdvanceFieldIsChecked] = useState(false);
    // const openPopup = (id) => {
    //     const data = dataList.find(item => item.id === id);
    //     setPopupData(data);
    //     setIsPopupOpen(true);
    // };

    // const closePopup = () => {
    //     setIsPopupOpen(false);
    //     setPopupData(null);
    // };

    const openPopup = async (e,tnNumber,date) => {
        e.preventDefault();
        const cid = formData.selectedCompany
        console.log(tnNumber,formData.selectedCompany,date);

        const response = await axios.post(`${baseUrl}/cia-get-ftc-data-by-tn-number`, { tnNumber: tnNumber,cid: cid, date:date });

        console.log(response?.data);

        setPopupData(response.data.data);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setPopupData(null);
    };

    //const dataListTwo = { additionalInfo: 'Some Extra Info', details: 'Detailed Description' };
    const openPopupTwo = async (e,path) => {
        e.preventDefault();
        const response = await axios.post(`${baseUrl}/get-dialed-number-file`, {path});
        const { presignedUrl } = response.data;
        setSelectedDataTwo(presignedUrl);
        setIsPopupTwoOpen(true);
    };

    const closePopupTwo = () => {
        setIsPopupTwoOpen(false);
        setSelectedDataTwo(null);
    };

    const handleCheckboxChange = (event) => {
        setAdvanceFieldIsChecked(event.target.checked);
        console.log("Checkbox is checked:", event.target.checked);
        // Add any other logic you want to handle here
    };

    const [formData,setFormData] = useState({
        selectedCompany: compid
    })
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUserAccess();
        getUserCompany();
    }, []);

    useEffect(()=>{
        if(formData.selectedCompany && token){
            getCustomerCASAccess(formData.selectedCompany)
           
                getCiaCallHistoryDate(formData.selectedCompany);
            
        }
    },[formData.selectedCompany, access, token])

    useEffect(() => {
        if(selectedBatchDate && formData.selectedCompany){
            getCiaCallHistoryBatchList(formData.selectedCompany);
        }
    }, [formData.selectedCompany, selectedBatchDate])

    // useEffect(() => {
    //     if(access.length > 0 && token){
    //         checkAccess(access, token);
    //     }
        
    // }, [access, token]);

    useEffect(() => {
        if(token && compid && filter){
            setFilterType(1);
            
            getCiaMostRecentDetail(1, 1,compid, carrier, selectedBatchDate, selectedBatch);
        }
       
        
    }, [compid, token, filter]);
    
   
   

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
            setCid(decoded.cid)
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            setAccess(response.data.results);
            
        } catch (error) {
           console.log(error);
        }
    }
    const getCiaCallHistoryDate = async (cid) => {
        try {
            console.log(cid);
            const response = await axiosJWT.get(`${baseUrl}/get-cia-call-history-dates/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            // const dateOptions = response.data.result.map(item => {
            //     const date = new Date(item.date); // Convert to Date object
            //     return date.toLocaleDateString(); // Format to only show the date part
            // });
            // console.log(dateOptions);
            setBatchDates(response.data.result);
            setSelectedBatchDate( response.data.result[0].date);
        } catch (error) {
           console.log(error);
        }
    }

    const getCiaCallHistoryBatchList = async (cid) => {
        try {
            console.log(cid);
            const response = await axiosJWT.post(`${baseUrl}/get-cia-call-history-batch/${cid}`, {date: selectedBatchDate}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            
            setBatchList(response.data.result);
            setSelectedBatch( response.data.result[0].id);
        } catch (error) {
           console.log(error);
        }
    }
    const getUserCompany = async () => {
        
        const response = await axiosJWT.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const allArray = response.data.filter(item => item.id !== 1);
        setCompany(allArray);
        // setCompany(response.data);
    }

   
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // setCid(decoded.cid)
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handleSearchSubmit = () => {
        setFilterSearch(true);
        setPage(1);
        
        getCiaMostRecentDetail(filterType,1,formData.selectedCompany, selectedCarrier, selectedBatchDate, selectedBatch);
    };

    const clearFilter = () => {
        setPage(1);
        setFilterType(0);
        setSelectedCarrier("0");
        setSelectedStatus("all");
        setFilterSearch(false);
        setTableData([]);
        setCarrierColumnName([]);
        setDateColumnName([]);
        setCarrierNumberList([]);
        setSearchDate(today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0'));
        setDisplayDate(today);
        setDisplayEndDate("");
        setSearchEndDate("");
        setSelectedBatch(selectedBatch?.[0]?.id ?? '');
        // getCiaMostRecentDetail(0, 1,formData.selectedCompany, "");
    };
    const getCiaMostRecentDetail = async (filterType, page,cid, carrier, selectedBatchDate, selectedBatch) => {
        try {
            setPopupOpen(true);
            if(filterType == 1){
                console.log(selectedBatchDate);
            
                const response = await axios.get(`${baseUrl}/get-cia-detail-carrier-statistics/${cid}?page=${page}&limit=${20}&carrier=${carrier}&batchDate=${selectedBatchDate}&batch=${selectedBatch}`,{
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                let columnList = ["Caller ID", "Carrier"];
                let list = [];
                if(response?.data?.cia_number_detail){
                    const dateKeys = Object.keys(response.data.cia_number_detail);
                    columnList = columnList.concat(dateKeys);
                    list = Object.values(response.data.cia_number_detail);
                }
                       
                columnList[2] = selectedBatchDate.split(".")[0];
                console.log(list);
                setCarrierColumnName(columnList);
                setCarrierNumberList(list)
                
                
            }

            if(filterType == 2){
                console.log(selectedBatchDate);
                const response = await axios.get(`${baseUrl}/get-cia-detail-date-statistics/${cid}?page=${page}&limit=${20}&from_date=${selectedBatchDate}&batch=${selectedBatch}`,{
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const carrier_list = response?.data?.carrier_list ?? [];
                
                let columnList = ["Caller ID", "Date","Stir Shaken Attestation Audit","CNAM Audit","Scan Risk Levels","Scan FTC report"];
                
                //const carrierNames = carrier_list.flatMap(carrier => [carrier.name, 'Android OCR','Android Screenshot']);

                const carrierNames = carrier_list.flatMap(carrier => [ `${carrier.name} App Status (Android)`,`${carrier.name} Caller ID Status (Android)`,`${carrier.name} OCR (Android)`,`${carrier.name} Android Screenshot`,`${carrier.name} App Status (iOS)`,`${carrier.name} Caller ID Status (iOS)`,`${carrier.name} OCR (iOS)`,`${carrier.name} iOS Screenshot`]);

                // const carrierNames = carrier_list.flatMap(carrier => [
                //     <>
                //       Caller ID Status (Android) <br />({carrier.name})
                //     </>, 
                //     <>
                //       Android OCR<br />({carrier.name})
                //     </>, 
                //     <>
                //       Android Screenshot<br />({carrier.name})
                //     </>, 
                //     <>
                //       IOS Status<br />({carrier.name})
                //     </>, 
                //     <>
                //       IOS OCR<br />({carrier.name})
                //     </>, 
                //     <>
                //       IOS Screenshot<br />({carrier.name})
                //     </>
                // ]);
                const updatedColumnList = columnList.concat(carrierNames);
                setDateColumnName(updatedColumnList);
                setCarrierList(carrier_list);
                //console.log(updatedColumnList);
                // const tableData = response?.data?.cia_number_detail.map((item) => {
                //     const row = {
                //         tn_number: item.tn_number,
                //         date: item.date.split(' ')[0]
                //     };
                //     carrier_list.forEach(carrier => {
                //         row[carrier.name] = '-';
                //     });
                
                //     if (item.carrier === '-') {
                //         Object.keys(row).forEach(key => {
                //             if (carrier_list.some(c => c.name === key)) {
                //                 row[key] = '-';
                //             }
                //         });
                //     } else {
                //         // Set the specific carrier column value
                //         const carrierName = carrier_list.find(c => c.id.toString() === item.carrier)?.name;
                //         if (carrierName) {
                //             const status = item.status === 0 ? "Unverified" : (item.status === 1 ? "Verified" : (item.state === 2 ? "Spam"  : '-'));
                //             row[carrierName] = status;
                //         }
                //     }
                
                //     return row;
                // });
                setTableData(response?.data?.cia_number_detail);
            }
            setPopupOpen(false);
        } catch (error) {
            console.log(error);  
            setPopupOpen(false);          
        }
    }

    // const checkAccess = async (access) => {
    //     try {
    //         const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }       
    //         });

    //         if (role > 2 && (response.data.cia_enable_switch === 0 || (response.data.cia_enable_switch === 1 && response.data.cia_account_type!== 1))) {
    //             navigate('/dashboard');
    //         }

    //         if(role > 2){
    //             setFormData(prev => ({...prev, selectedCompany:cid}))
    //             getCustomerCASAccess(cid)
    //         }
            
    //             // if(response.data.cia_enable_switch === 1 && response.data.cia_account_type === 0){
    //             //         if(role > 2) navigate('/dashboard')
    //             // }else{
    //             //     navigate('/dashboard')
    //             // }

    //     } catch (error) {
    //        console.log(error);
    //     }
    // }

    const handlePageChange  = (pageNumber) => {
        setPage(pageNumber);
    }

    const getCustomerCASAccess = async (cid) => {
        try {
            setPopupOpen(true);
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            
            if (role > 2 && (response.data.cia_enable_switch === 0 || (response.data.cia_enable_switch === 1 && response.data.cia_account_type!== 1))) {
                navigate('/dashboard');
                return;
            }
            if(response.data.cia_enable_switch === 1){
                if(response.data.cia_account_type === 0 && role > 2){
                        setCASAccess(false)
                        setShowNoMessageAccess(true)
                }else{
                    setShowNoMessageAccess(false);
                    setCASAccess(true)
                    
                }
            }else{
                setCASAccess(false)
                setShowNoMessageAccess(true)
            }
            setPopupOpen(false);
        } catch (error) {
           console.log(error);
        }
    };

    const getEnrmsScreenshot = async (e,path) => {
        try {
            e.preventDefault();
        // Make a request to your backend to get the pre-signed URL
        //const path = filePath;
        const response = await axios.post(`${baseUrl}/get-dialed-number-file`, {path});
        const { presignedUrl } = response.data;

        window.open(presignedUrl, '_blank');
        // Create a temporary anchor element and trigger a download
        // const link = document.createElement('a');
        // link.href = presignedUrl;
        // link.download = path.split('/').pop(); // Set the filename as the last part of the file path
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // const newTab = window.open('', '_blank');
        // newTab.document.write('<html><head><title>JSON Object</title></head><body><pre>' + JSON.stringify(response.data, null, 2) + '</pre></body></html>');
        // newTab.document.close();
        } catch (error) {
        console.error('Error downloading the file', error);
        }
    };

    // const getEnrmsScreenshot = async (e,path) => {
    //     e.preventDefault();
    //     try {
    //         setPopupOpen(true);
    //         // setApiCallCount((prevCount) => prevCount + 1);

    //         const postData = {"path": path};
    //         const response = await axios.post(`${baseUrl}/get-enrms-screenshots`,postData, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }       
    //         });
            
    //        console.log(response.data.image);

    //        const blob = new Blob([response.data.image], { type: "image/jpeg" }); // Replace with the appropriate MIME type if needed
    //        // Generate a URL for the blob
    //        const imageUrl = URL.createObjectURL(blob);
    //         console.log(imageUrl);
    //         setImageSrc(imageUrl);
            
    //        // Clean up the object URL when the component unmounts
    //        //return () => URL.revokeObjectURL(imageUrl);
           
    //         setPopupOpen(false);
    //     } catch (error) {
    //        console.log(error);
    //     }
    // };

    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);  
        setFormData({ ...formData, selectedCompany: e.target.value });
        clearFilter();

    };

    

    const handleExportCSV = async (e) => {
        e.preventDefault();
        if(filterType == 1){
            //const columnList = ["Date","Caller ID", "Carrier", "Stir Shaken Attestation Audit", "CNAM Audit", "Scan Risk Levels", "Scan FTC Report", "Carrier Info. (Android)","Caller ID Status (Android) ", "OCR (Android)", "Android Screenshot", "Caller ID Status (iOS) ", "OCR (iOS)", "OS Screenshot"];
            const columnList = ["Date","Caller ID", "Carrier", "Stir Shaken Attestation Audit", "CNAM Audit", "Scan Risk Levels", "Scan FTC Report","Caller ID Status (Android) ","Caller ID Status (iOS) "];
            // if(carrierColumnName[0] || carrierColumnName[1]){
            //     columnList[0] = "";
            //     columnList[1] = "";
            // }
            // if(carrierColumnName[2]){
            //     columnList[2] = "Status";
            //     columnList[3] = "Caller Id"; 
            // }
            // if(carrierColumnName[3]){
            //     columnList[4] = "Status";
            //     columnList[5] = "Caller Id";
            // }
            // if(carrierColumnName[4]){
            //     columnList[6] = "Status";
            //     columnList[7] = "Caller Id";
            // }
            // if(carrierColumnName[5]){
            //     columnList[8] = "Status";
            //     columnList[9] = "Caller Id";
            // }
            //console.log(tableData)
            // const csvContent = tableData.map(item => {
                
            //     return [...item.map(val => (val != null ? val.toString().replace(/,/g, ' ') : ''))];
                
            // }).join("\n");

            const csvContent = tableData.map(item => {
                return item
                    .filter((val, index) => index !== 7 && index !== 9 &&  index !== 10 && index !== 11 && index !== 13) // Exclude indices 7, 9, and 11
                    .map((val, index) => {
                        // Check if index is 7 or 10, and perform the split logic
                        if ((index === 7 || index === 8) && val != null) {
                            return val.toString().replace(/,/g, ' ').split('|')[0]; // Split by space, get first word
                        }
                        // For other indices, just return the value as a string (replace commas with spaces)
                        return val != null ? escapeCsvValue(val.toString().replace(/,/g, ' ')) : '';
                    })
                    .join(","); // Join the values with commas for CSV format
            }).join("\n"); // Join rows with newlines for CSV rows
            

            
            const csv = [columnList, csvContent].join('\n');
            const blob = new Blob([csv], { type: 'text/csv' });

            saveAs(blob, 'cia_enable_number.csv');
        }else if(filterType == 2){
            // const csv = [dateColumnName].concat(
            //     tableData.map(item => {
            //         return [
            //             item.tn_number,
            //             item.date,
            //             ...carrierList.map((val) => {
            //                 return `${item[`carrier_${val.id}`]},${item[`caller_${val.id}`].toString().replace(/,/g, ' ')}`;
            //               })
            //         ].join(',');
            //     })).join('\n');
                
            // const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          
            // // Trigger the file download using file-saver
            // saveAs(blob, 'cia_enable_number.csv');
            // const columnList2 = [
            //     dateColumnName[0],
            //     dateColumnName[1],
            //     dateColumnName[2],
            //     dateColumnName[3],
            //     dateColumnName[4],
            //     dateColumnName[5],
            //     //dateColumnName[6],
            //     dateColumnName[7],
            //     // dateColumnName[8],
            //     // dateColumnName[9],
            //     // dateColumnName[10],
            //     dateColumnName[11],
            //     // dateColumnName[12],
            //     // dateColumnName[13],
            //     // dateColumnName[14],
            //     dateColumnName[15],
            //     // dateColumnName[16],
            //     // dateColumnName[17],
            //     // dateColumnName[18],
            //     dateColumnName[19],
            //     // dateColumnName[20],
            //     // dateColumnName[21],
            //     // dateColumnName[22],
            //     dateColumnName[23],
            //     // dateColumnName[24],
            //     // dateColumnName[25],
            //     // dateColumnName[26],
            //     dateColumnName[27],
            //     // dateColumnName[28],
            //     // dateColumnName[29],
            //  ];
            //  console.log(columnList2);
             const columnList = [
                dateColumnName[0],
                dateColumnName[1],
                dateColumnName[2],
                dateColumnName[3],
                dateColumnName[4],
                dateColumnName[5],
                //dateColumnName[6],
                dateColumnName[7],
                // dateColumnName[8],
                // dateColumnName[9],
                // dateColumnName[10],
                dateColumnName[11],
                // dateColumnName[12],
                // dateColumnName[13],
                // dateColumnName[14],
                dateColumnName[15],
                // dateColumnName[16],
                // dateColumnName[17],
                // dateColumnName[18],
                dateColumnName[19],
                // dateColumnName[20],
                // dateColumnName[21],
                // dateColumnName[22],
                dateColumnName[23],
                // dateColumnName[24],
                // dateColumnName[25],
                // dateColumnName[26],
                dateColumnName[27],
                // dateColumnName[28],
                // dateColumnName[29],
             ];
             console.log(columnList);
            const csvContent = tableData.map(item => {

                const escapeCsvDateValue = (value) => {
                    if (typeof value === 'string') {
                        // Escape existing double quotes by doubling them
                        value = value.replace(/"/g, '""');
                        // Wrap the value in double quotes if it contains commas or quotes
                        if (value.includes(',') || value.includes('"')) {
                            return `"${value}"`;
                        }
                    }
                    return value;
                };
                // Start with basic information (tn_id, tn_number, date)
                let row = [
                    item.tn_number,
                    item.date,
                    item.attestationAudit,
                    item.cnam,
                    (item.scanRiskLevel !== null && item.scanRiskLevel !== undefined && item.scanRiskLevel !== '' ? `Risk level : ${item.scanRiskLevel}` : "-"),
                    (item.scanFTCReport !== undefined && item.scanFTCReport !== null) ? (item.scanFTCReport == 1 ? escapeCsvDateValue(item.ftcSubject) : 'Nothing reported') : ''
                ];
                
                //  carrierList.map((val, i) => {
                    
                //     row.push(
                //     item[`carrier_${i + 1}`]?.android_status || '-',
                //     escapeCsvValue( item[`carrier_${i + 1}`]?.andriod_ocr || '-'),
                //     item[`carrier_${i + 1}`]?.screenshot_android || '-',
                //     item[`carrier_${i + 1}`]?.ios_status || '-',
                //     escapeCsvValue(item[`carrier_${i + 1}`]?.ios_ocr || '-'),
                //     item[`carrier_${i + 1}`]?.screenshot_ios || '-')
                // })
                carrierList.map((val, i) => {
                    const carrier = item[`carrier_${i + 1}`]; // Store the carrier value in a variable
                    if (carrier) {
                        row.push(
                            carrier.android_status || '-',
                            //escapeCsvValue(carrier.andriod_ocr || '-'),
                            //carrier.screenshot_android || '-',
                            carrier.ios_status || '-',
                            //escapeCsvValue(carrier.ios_ocr || '-'),
                            //carrier.screenshot_ios || '-'
                        );
                    }
                });
               
            
                // Join all row values with commas for CSV format
                return row.join(",");
            }).join("\n");
            
            // console.log(csvContent);
            const csv = [columnList, csvContent].join('\n');
            const blob = new Blob([csv], { type: 'text/csv' });

            saveAs(blob, 'cia_enable_number.csv');
          
        }
       
    }

    useEffect(() => {
        setPopupOpen(true);
        const phoneNumbers = new Set();
        const carriers = new Set();
        const dates = Object.keys(carrierNumberList);

        // Collect unique phone numbers and carriers
        for (const date in carrierNumberList) {
            carrierNumberList[date].forEach(item => {
                phoneNumbers.add(item.tn_number);
                carriers.add(item.carrier);
            });
        }

        const phoneNumbersArray = Array.from(phoneNumbers);
        const carriersArray = Array.from(carriers);

        let rows = phoneNumbersArray.flatMap(phoneNumber =>
            carriersArray.map(carrier => {
                const row = [];
                // row.push(phoneNumber);
                // row.push(carrier);
                //const row = ['',phoneNumber, carrier];
                dates.forEach(date => {
                    const item = carrierNumberList[date].find(i => i.tn_number === phoneNumber && i.carrier === carrier);
                    const auditttem = carrierNumberList[date].find(i => i.tn_number === phoneNumber);
                    //const status = (item.status === 0 ? "Unverified" : (item.status === 1 ? "Verified" : (item.status === 2 ? "Spam"  : 'Unknown')));
                    // console.log(auditttem);
                    //row.push(item.android_status);
                    // let scanRiskLevel = `Risk level : ${auditttem.scanRiskLevel}`;
                    // let scanFTCReport = (scanFTCReport==1) ? 'Reported' : 'Not Reported';
                    row.push(item.call_date_time);
                    row.push(item.tn_number);
                    row.push(item.carrier);
                    auditttem !== undefined && row.push(auditttem.attestationAudit);
                    auditttem !== undefined && row.push(auditttem.cname);
                    auditttem !== undefined && row.push(auditttem.scanRiskLevel !== null && auditttem.scanRiskLevel !== undefined && auditttem.scanRiskLevel !== '' ? `Risk level : ${auditttem.scanRiskLevel}` : "-");
                    auditttem !== undefined && row.push((auditttem.scanFTCReport==1) ? auditttem.ftcSubject : 'Nothing reported');
                    item !== undefined && row.push(item.device_status);
                    //item !== undefined && row.push(item.device_status_ios);
                    //item !== undefined && row.push(item.carrier_status_android);
                    //item !== undefined && row.push(item.android_status);

                    if (item !== undefined) {
                        row.push(`${item.android_status}|${item.screenshot_android}`);
                        row.push(item.carrier_status_android);
                    }

                    item !== undefined && row.push(item.andriod_ocr);
                    if (item !== undefined)
                    {
                        row.push(item.device_status_ios);
                        row.push(`${item.ios_status}|${item.screenshot_ios}`);
                        row.push(item.ios_ocr);
                    }
                    //item !== undefined && row.push(item.screenshot_android);
                    //item !== undefined && row.push(item.ios_status);
                    // if (item !== undefined) 
                    // {
                    //     row.push(`${item.ios_status}|${item.screenshot_ios}`);
                    // }
                    //item !== undefined && row.push(item.ios_ocr);
                    //item !== undefined && row.push(item.screenshot_ios);
                    // row.push("-");
                    // row.push(item.andriod_ocr);
                    // row.push(item.screenshot_android);
                    // row.push(item.ios_status);
                    // // row.push("-");
                    // row.push(item.ios_ocr);
                    // row.push(item.screenshot_ios);
                    /////// Android carrier status ////////
                });
                return row;
            })
        );
        const status = selectedStatus == 1 ? "Normal" : (selectedStatus == 2 ? "Spam" : (selectedStatus == 3 ? "Unknown" : ""));
        if(status){
            rows = rows.filter(row => {
                return row[8].split('|')[0].trim() === status || row[12].split('|')[0].trim() === status});
            }
        setTableData([ ...rows]);
        setPopupOpen(false);
    }, [carrierNumberList]);

    const handleSearchDate = (date, name) => { 
        const formattedDate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
            date.getDate().toString().padStart(2, '0');
        if(name == "from_date"){
            setSearchDate(formattedDate);
            setDisplayDate(date);
        }else if(name == "end_date"){
            setSearchEndDate(formattedDate);
            setDisplayEndDate(date);
        }
    };
   
    const handleFilterChange =  (e) => {
        setFilterType(e.target.value);
        setTableData([]);
        setCarrierColumnName([]);
        setDateColumnName([]);
    }

    const gotoSwitchScreen = (e) => {
        e.preventDefault();
        const state = { state: {compid : selectedValue}}
        navigate("/cas-management", state);
    }

    const escapeCsvValue = (value) => {
        if (typeof value === 'string') {
            // Escape existing double quotes by doubling them
            value = value.replace(/"/g, '""');
            // Wrap the value in double quotes if it contains commas or quotes
            if (value.includes(',') || value.includes('"')) {
                return `"${value}"`;
            }
        }
        return value;
    };

  return (
    <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
            {imageSrc &&
        <a href={imageSrc} target="_blank" rel="noopener noreferrer">
          <img src={imageSrc} alt="Buffer Image" style={{ cursor: "pointer" }} />
        </a> }
      
                <div className="content-page admin-attendance-page">
                    <div className="row">
                    <div className="col-8">
                        <div className="left-title">
                            <h1>E-NRMS Number Details</h1>
                        </div>
                    </div>
                    <div className="col-4 inventory-btn">                      
                        <div className="addnew-button">
                        <a href="#" onClick={(e) => gotoSwitchScreen(e)}>Back</a>
                            {/* <Link to="/cas-management">Back</Link> */}
                        </div>
                    </div>
                    </div>

                    {(role <= 2) && <div className="common-white-shadow-background setting-section">
                        <div className="row">
                            <h3 className='comapny-label'>Select Company</h3>
                            <div className="inputbox">
                                <select name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                                    <option value="">Select Company</option>
                                    {company.map((comp) => ( 
                                            <option key={comp.id} value={comp.id}>
                                                {comp.cname}
                                            </option>  
                                        ))}
                                </select>
                            </div>
                        </div>
                    </div>}

                    {showNoAccessMessage && 
                    <div className="common-white-shadow-background setting-section">
                        <div className="row error">You don't have access to the features.</div>
                    </div>}

                    {casAccess && <>
                    
                        
                        <div className='common-white-shadow-background setting-section'>
                            
                            <div className='row'>
                                <div className='col-10'>
                                <div className='boxinput'>
                                    <select className="form-control" name='filter_type' value={filterType} onChange={(e)=>{handleFilterChange(e)}}>
                                        <option value='0'>Select a Carrier or Date</option>
                                        <option value='1'>By Carrier</option>
                                        <option value='2'>By Date</option>
                                    </select>
                                    {filterType == 1 && <select className="form-control" name='carriertype' value={selectedCarrier} onChange={(e)=>{setSelectedCarrier(e.target.value)}}>
                                        <option value='0'>All</option>
                                        <option value='1'>T-Mobile</option>
                                        <option value='2'>ATT</option>
                                        <option value='3'>Verizon</option>
                                    </select>}
                                    {filterType == 1 && <select className="form-control" name='carrierStatus' value={selectedStatus} onChange={(e)=>{setSelectedStatus(e.target.value)}}>
                                        <option value='all'>All</option>
                                        <option value='1'>Normal</option>
                                        <option value='2'>Spam</option>
                                        <option value='3'>Unknown</option>
                                        
                                    </select>}
                                    {(filterType == 1 || filterType == 2) && <select className="form-control" name='carrierStatus' value={selectedBatchDate} onChange={(e)=>{setSelectedBatchDate(e.target.value)}}>
                                      {batchDates && batchDates.map((item) => (
                                        <option value={item.date}>{item.date.split(" ")[0]}</option>
                                      ))}
                                    </select>}
                                    {(filterType == 1 || filterType == 2) && <select className="form-control" name='carrierStatus' value={selectedBatch} onChange={(e)=>{setSelectedBatch(e.target.value)}}>
                                      {batchList && batchList.map((item) => (
                                        <option value={item.id}>{item.id}</option>
                                      ))}
                                    </select>}
                                    {/* {filterType == 2 && 
                                    <>
                                    <DatePicker
                                        selected={displayDate}
                                        onChange={(e) => {handleSearchDate(e, "from_date")}}
                                        dateFormat="MM/dd/yyyy" // Set the desired date format
                                        placeholderText="From Date"
                                        className="from-date"
                                        minDate={sixMonthsAgo}
                                        maxDate={today}
                                    />
                                    <DatePicker
                                        selected={displayEndDate}
                                        onChange={(e) => {handleSearchDate(e, "end_date")}}
                                        dateFormat="MM/dd/yyyy" // Set the desired date format
                                        placeholderText="To Date"
                                        className="end-date"
                                        minDate={sixMonthsAgo}
                                        maxDate={today}
                                    /> </>} */}
                                    
                                    
                                    {(filterType == 1 || filterType == 2) && <button className="search-btn" style={{marginLeft:'10px'}} onClick={handleSearchSubmit}>Search</button>}
                                    {(filterType == 1 || filterType == 2) && selectedCarrier && <a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>}
                                </div>
                                </div>
                                {tableData && tableData.length > 0 && <div className="col-2 inventory-btn">
                                    <div className="addnew-button">
                                        <a className="newrow" onClick={handleExportCSV} href="#">Export</a>
                                    </div>
                                </div>}
                            </div>
                            {((filterType == 1 || filterType == 2) && role <= 2)  && <div className="row">
                            <div className='advance-settingbox'>
                                <div className="advance_checkbox switchbtn pull-right">
                                    <label>Show All Info &nbsp;&nbsp;</label>
                                    <input 
                                        type="checkbox" 
                                        id={`advanceFieldCheckbox`}  
                                        className="checkbox" 
                                        name="status" 
                                        checked={isAdvanceFieldChecked}
                                        onChange={handleCheckboxChange} // Change event handler
                                    /> 
                                    <label htmlFor={`advanceFieldCheckbox`} className="toggle">
                                        {/* <p>{isAdvanceFieldChecked ? "Advance" : "Default"}</p> */}
                                    </label>
                                    </div>
                                </div>
                            </div>}
                            <div className="row">
                                <div className="setting-section haft-whitebox cia-table cia-table-enable-number">
                                    {filterType == 1 && <table className={`tablebox search-result-table inventory-table cia-number-table ${filterType == 1 ? "carrier-table" : "date-table"}`}>
                                        {carrierColumnName && carrierColumnName.length > 0 && <thead>

                                            {/* <tr>
                                                
                                                {carrierColumnName[0] && <th>{carrierColumnName[0]}</th>}
                                                {carrierColumnName[1] && <th>{carrierColumnName[1]}</th>}
                                                {carrierColumnName[1] && <th></th>}
                                                {carrierColumnName[1] && <th></th>}
                                                {carrierColumnName[1] && <th></th>}
                                                {carrierColumnName[1] && <th></th>}
                                                {carrierColumnName[2] && <th style={{textAlign: "center"}} colSpan={2}>{carrierColumnName[2]}</th>}
                                                {carrierColumnName[3] && <th style={{textAlign: "center"}}  colSpan={2}>{carrierColumnName[3]}</th>}
                                                {carrierColumnName[4] && <th style={{textAlign: "center"}}  colSpan={2}>{carrierColumnName[4]}</th>}
                                                {carrierColumnName[5] && <th style={{textAlign: "center"}}  colSpan={2}>{carrierColumnName[5]}</th>}
                                            </tr> */}
                                            <tr>
                                                {carrierColumnName[0] && <th>Date</th>}
                                                {carrierColumnName[0] && <th>{carrierColumnName[0]}</th>}
                                                {carrierColumnName[1] && <th>{carrierColumnName[1]}</th>}
                                                {carrierColumnName[1] && <th>Stir Shaken Attestation Audit</th>}
                                                {carrierColumnName[1] && <th>CNAM Audit</th>}
                                                {carrierColumnName[1] && <th>Scan Risk Levels</th>}
                                                {carrierColumnName[1] && <th>Scan FTC Report</th>}
                                                {(carrierColumnName[1] && isAdvanceFieldChecked) && <th>App Status (Andriod)</th>}
                                                {carrierColumnName[2] && <th>Caller ID Status (Android) </th>}
                                                {/* {(carrierColumnName[1] && isAdvanceFieldChecked) && <th>App Status (iOS)</th>} */}
                                                {(carrierColumnName[1] && isAdvanceFieldChecked) && <th>Carrier Info. (Android)</th>}
                                                {(carrierColumnName[2] && isAdvanceFieldChecked) && <th>OCR (Android)</th>}
                                                {/* {carrierColumnName[2] && <th>Android Screenshot</th>} */}
                                                {(carrierColumnName[1] && isAdvanceFieldChecked) && <th>App Status (iOS)</th>}
                                                {carrierColumnName[2] && <th>Caller ID Status (iOS) </th>}
                                                {(carrierColumnName[2]&& isAdvanceFieldChecked) && <th>OCR (iOS)</th>}
                                                {/* {carrierColumnName[2] && <th>iOS Screenshot</th>} */}
                                                
                                                {carrierColumnName[3] && <th>Caller ID Status (Android) </th>}
                                                {(carrierColumnName[3] && isAdvanceFieldChecked) && <th>OCR (Android)</th>}
                                                {/* {carrierColumnName[3] && <th>Android Screenshot</th>} */}
                                                {carrierColumnName[3] && <th>Caller ID Status (iOS) </th>}
                                                {(carrierColumnName[3] && isAdvanceFieldChecked) && <th>OCR (iOS)</th>}
                                                {carrierColumnName[3] && <th>iOS Screenshot</th>}

                                                {carrierColumnName[4] && <th>Caller ID Status (Android) </th>}
                                                {(carrierColumnName[4] && isAdvanceFieldChecked) && <th>OCR (Android)</th>}
                                                {/* {carrierColumnName[4] && <th>Android Screenshot</th>} */}
                                                {carrierColumnName[4] && <th>Caller ID Status (iOS) </th>}
                                                {(carrierColumnName[4] && isAdvanceFieldChecked) && <th>OCR (iOS)</th>}
                                                {/* {carrierColumnName[4] && <th>iOS Screenshot</th>} */}

                                                {carrierColumnName[5] && <th>Caller ID Status (Android) </th>}
                                                {(carrierColumnName[5] && isAdvanceFieldChecked) && <th>OCR (Android)</th>}
                                                {/* {carrierColumnName[5] && <th>Android Screenshot</th>} */}
                                                {carrierColumnName[5] && <th>Caller ID Status (iOS) </th>}
                                                {(carrierColumnName[5] && isAdvanceFieldChecked) && <th>OCR (iOS)</th>}
                                                {/* {carrierColumnName[5] && <th>iOS Screenshot</th>} */}
                                            </tr>
                                        </thead>}
                                        <tbody>
                                        {tableData.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {/* <td>{carrierColumnName[2]}</td> */}
                                                {row.map((cell, cellIndex) => (
                                                    //<td>{cell} - {cellIndex}</td>
                                                   (!isAdvanceFieldChecked && (cellIndex === 7 || cellIndex === 9 ||cellIndex === 10 || cellIndex === 11 || cellIndex === 13)) ? null : (
                                                     <td key={cellIndex}>
                                                     {((cellIndex === 8 || cellIndex === 12)) && ((cell.split('|')[0].trim()) == 'Normal' || (cell.split('|')[0].trim()) == 'Spam') && cell !== "" ? (
                                                       <a  href="#" onClick={(e) => openPopupTwo(e,cell.split('|')[1].trim())}>{cell.split('|')[0].trim()}</a>
                                                     ) : (cellIndex === 6 && cell !== 'Nothing reported') ? (<a  href="#" onClick={(e) => openPopup(e,row[1],row[0])}>{cell}</a>) : 
                                                     (
                                                        typeof cell === 'string' && cell.includes('|') && (cellIndex == 8 || cellIndex == 12) ? (
                                                            <span>{cell.split('|')[0].trim()}</span> // You can style or handle the cell differently if it contains a comma
                                                        ) : (
                                                            cell // Just return the cell as is if there is no comma
                                                        )
                                                    )}
                                                   </td>
                                                  )
                                                ))}
                                                {/* {row.map((cell, cellIndex) => (
                                                    (!isAdvanceFieldChecked && (cellIndex === 7 || cellIndex === 9 || cellIndex === 11)) ? null : (
                                                     <td key={cellIndex}>
                                                     {((cellIndex === 8 || cellIndex === 10)) && (cell.split('|')[0].trim()) == 'Normal' && cell !== "" ? (
                                                       <a  href="#" onClick={(e) => openPopupTwo(cell.split('|')[1].trim())}>{cell.split('|')[0].trim()}</a>
                                                     ) : (cellIndex === 6 && cell !== 'Nothing reported') ? (<a  href="#" onClick={(e) => openPopup(row[1],row[0])}>{cell}</a>) : 
                                                     (
                                                        typeof cell === 'string' && cell.includes('|') && (cellIndex == 8 || cellIndex == 10) ? (
                                                            <span>{cell.split('|')[0].trim()}</span> // You can style or handle the cell differently if it contains a comma
                                                        ) : (
                                                            cell // Just return the cell as is if there is no comma
                                                        )
                                                    )}
                                                   </td>
                                                    )
                                                ))}  */}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>}
                                    {isPopupOpen && <Popup data={popupData} onClose={closePopup} />}
                                    {isPopupTwoOpen && <PopupImage data={selectedDataTwo} onClose={closePopupTwo} />}
                                    {filterType == 2 && <table className="tablebox search-result-table inventory-table">
                                        {dateColumnName && dateColumnName.length > 0 && <thead>

                                            <tr>
                                                {dateColumnName[0] && <th>{dateColumnName[0]}</th>}
                                                {dateColumnName[1] && <th>{dateColumnName[1]}</th>}
                                                {dateColumnName[2] && <th>{dateColumnName[2]}</th>}
                                                {dateColumnName[3] && <th>{dateColumnName[3]}</th>}
                                                {dateColumnName[4] && <th>{dateColumnName[4]}</th>}
                                                {dateColumnName[5] && <th>{dateColumnName[5]}</th>}
                                                {(dateColumnName[6] && isAdvanceFieldChecked) && <th>{dateColumnName[6]}</th>}
                                                {dateColumnName[7] && <th>{dateColumnName[7]}</th>}
                                                {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[8]}</th>}
                                                {/* {(dateColumnName[7] && isAdvanceFieldChecked) &&  <th>{dateColumnName[9]}</th>} */}
                                                {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[10]}</th>}
                                                {(dateColumnName[7]) && <th>{dateColumnName[11]}</th>}
                                                {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[12]}</th>} 
                                                {/* {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[13]}</th>} */}
                                                {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[14]}</th>}
                                                {(dateColumnName[7]) && <th>{dateColumnName[15]}</th>}
                                                {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[16]}</th>}
                                                {/* {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[17]}</th>} */}
                                                {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[18]}</th>}
                                                {dateColumnName[7] && <th>{dateColumnName[19]}</th>} 
                                                {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[20]}</th>}
                                                {/* {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[21]}</th>} */}
                                                {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[22]}</th>}
                                                {dateColumnName[7] && <th>{dateColumnName[23]}</th>} 
                                                {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[24]}</th>}
                                                {/* {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[25]}</th>} */}
                                                {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[26]}</th>}
                                                {(dateColumnName[7]) && <th>{dateColumnName[27]}</th>}
                                                {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[28]}</th>}
                                                {/* {(dateColumnName[7] && isAdvanceFieldChecked) && <th>{dateColumnName[29]}</th>} */}
                                            </tr>
                                        </thead>}
                                        <tbody>
                                            {tableData.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    <td>{row.tn_number}</td>
                                                    <td>{row.date}</td>
                                                    <td>{row.attestationAudit}</td>
                                                    <td>{row.cnam}</td>
                                                    <td>{(row.scanRiskLevel !== null && row.scanRiskLevel !== undefined && row.scanRiskLevel !== '' ? `Risk level : ${row.scanRiskLevel}` : "-")}</td>
                                                    <td>{(row.scanFTCReport !== undefined && row.scanFTCReport !== null) ? (row.scanFTCReport == 1 ? (<a  href="#" onClick={(e) => openPopup(e,row.tn_number,row.date)}>{row.ftcSubject}</a>)  : 'Nothing reported') : ''}</td>
                                                    {/* <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td> */}
                                                    {carrierList.map((item, i) => {
                                                    const carrier = row[`carrier_${i + 1}`]; // Store the carrier value in a variable for reuse
                                                    if (carrier) {
                                                        return (
                                                            <React.Fragment key={`${rowIndex}-${i}`}>
                                                                {/* <td>{carrier.android_status || '-'}</td> */}
                                                                {isAdvanceFieldChecked && <td>{carrier.device_status}</td>}
                                                                <td>{(carrier.android_status == 'Normal' || carrier.android_status == 'Spam') ? (<a  href="#" onClick={(e) => openPopupTwo(e,carrier.screenshot_android)}>{carrier.android_status}</a>) : carrier.android_status || '-'}</td>
                                                                {isAdvanceFieldChecked && <td>{carrier.andriod_ocr || '-'}</td>}
                                                                {/* <td>
                                                                    <a href="#" onClick={(e) => getEnrmsScreenshot(e, carrier.screenshot_android || '-')}>
                                                                        {carrier.screenshot_android || '-'}
                                                                    </a>
                                                                </td> */}
                                                                {/* <td>{carrier.ios_status || '-'}</td> */}
                                                                {isAdvanceFieldChecked && <td>{carrier.device_status_ios}</td>}
                                                                <td>{(carrier.ios_status == 'Normal' || carrier.ios_status == 'Spam') ? (<a  href="#" onClick={(e) => openPopupTwo(e,carrier.screenshot_ios)}>{carrier.ios_status}</a>) : carrier.ios_status || '-'}</td>

                                                                {isAdvanceFieldChecked &&  <td>{carrier.ios_ocr || '-'}</td>}
                                                                {/* <td>
                                                                    <a href="#" onClick={(e) => getEnrmsScreenshot(e, carrier.screenshot_ios || '-')}>
                                                                        {carrier.screenshot_ios || '-'}
                                                                    </a>
                                                                </td> */}
                                                            </React.Fragment>
                                                        );
                                                    }
                                                    return null; // Return null if carrier doesn't exist
                                                })}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>}
                                    {totalItem > 20 && (<Pagination
                                      activePage={page}
                                      itemsCountPerPage={20}
                                      totalItemsCount={totalItem}
                                      pageRangeDisplayed={5}
                                      onChange={handlePageChange.bind(this)}
                                    />)}
                                </div>
                            </div>
                        </div>
                        
                    </>}

                </div>
            </div>
    </div>
  )
}

export default CiaNumberDetail